import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/LayoutTop'
import BlogRoll from '../components/BlogRoll'
import { kebabCase } from 'lodash'

export const IndexPageTemplate = ({
  image,
  title,
  subheading,
}) => (
  <div>
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `top left`,
        backgroundAttachment: `fixed`,
      }}
    >
      <div
        style={{
          display: 'flex',
          height: '150px',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          flexDirection: 'column',
        }}
      >
        <h1
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
          style={{
            boxShadow:
              'rgba(52, 73, 94, 0.8) 0.5rem 0px 0px, rgba(52, 73, 94, 0.8) -0.5rem 0px 0px',
            backgroundColor: 'rgba(52, 73, 94, 0.8)',
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
          }}
        >
          {title}
        </h1>
        <h3
          className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
          style={{
            boxShadow:
              'rgba(52, 73, 94, 0.8) 0.5rem 0px 0px, rgba(52, 73, 94, 0.8) -0.5rem 0px 0px',
            backgroundColor: 'rgba(52, 73, 94, 0.8)',
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
          }}
        >
          {subheading}
        </h3>
      </div>
    </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="content">
          
          <h2 className="indexTitle">地域から選ぶ</h2>
          <div className="indexPage columns is-12 has-text-centered is-multiline">
            <div className="is-parent column topColumn is-4">
              <Link to={"/tags/新宿区/"}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>新宿区</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={"/tags/渋谷区/"}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>渋谷区</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={"/tags/豊島区/"}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>豊島区</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={"/tags/品川区/"}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>品川区</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={"/tags/目黒区/"}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>目黒区</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={"/tags/千代田区/"}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>千代田区</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={"/tags/中央区/"}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>中央区</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={"/tags/江東区/"}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>江東区</h3>
                </article>
              </Link>
            </div>
          </div>
        
          <h2 className="indexTitle">築年数から選ぶ</h2>
          <div className="indexPage columns is-multiline has-text-centered">
            <div className="is-parent column topColumn is-4">
              <Link to={"/tags/1985-1989/"}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>1985-1989年</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={"/tags/1990-1994/"}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>1990-1994年</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={"/tags/1995-1999/"}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>1995-1999年</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={"/tags/2000-2004/"}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>2000-2004年</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={"/tags/2005-2009/"}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>2005-2009年</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={"/tags/2010-2014/"}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>2010-2014年</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={"/tags/2015-2019/"}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>2015-2019年</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={"/tags/2020-2024/"}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>2020-2024年</h3>
                </article>
              </Link>
            </div>
          </div>

          <h2 className="indexTitle">建物階数から選ぶ</h2>
          <div className="content">
            <div className="indexPage columns is-12 has-text-centered is-multiline">
              <div className="is-parent column topColumn is-4">
                <Link to={`/tags/${kebabCase("-20階")}/`}>
                  <article className={`blog-list-item tile is-child box topBox`} >
                    <h3>-20階</h3>
                  </article>
                </Link>
              </div>
              <div className="is-parent column topColumn is-4">
                <Link to={`/tags/${kebabCase("21-25階")}/`}>
                  <article className={`blog-list-item tile is-child box topBox`} >
                    <h3>21-25階</h3>
                  </article>
                </Link>
              </div>
              <div className="is-parent column topColumn is-4">
                <Link to={`/tags/${kebabCase("26-30階")}/`}>
                  <article className={`blog-list-item tile is-child box topBox`} >
                    <h3>26-30階</h3>
                  </article>
                </Link>
              </div>
              <div className="is-parent column topColumn is-4">
                <Link to={`/tags/${kebabCase("31-35階")}/`}>
                  <article className={`blog-list-item tile is-child box topBox`} >
                    <h3>31-35階</h3>
                  </article>
                </Link>
              </div>
              <div className="is-parent column topColumn is-4">
                <Link to={`/tags/${kebabCase("36-40階")}/`}>
                  <article className={`blog-list-item tile is-child box topBox`} >
                    <h3>36-40階</h3>
                  </article>
                </Link>
              </div>
              <div className="is-parent column topColumn is-4">
                <Link to={`/tags/${kebabCase("41-50階")}/`}>
                  <article className={`blog-list-item tile is-child box topBox`} >
                    <h3>41-50階</h3>
                  </article>
                </Link>
              </div>
              <div className="is-parent column topColumn is-4">
                <Link to={`/tags/${kebabCase("51-60階")}/`}>
                  <article className={`blog-list-item tile is-child box topBox`} >
                    <h3>51-60階</h3>
                  </article>
                </Link>
              </div>
            </div>
          </div>

          <h2 className="indexTitle">間取りから選ぶ</h2>
          <div className="indexPage columns is-12 has-text-centered is-multiline">
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("1R")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>1R</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("1K")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>1K</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("1DK")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>1DK</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("1LDK")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>1LDK</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("2DK")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>2DK</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("2LDK")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>2LDK</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("2SLDK")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>2SLDK</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("3LDK")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>3LDK</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("4LDK以上")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>4LDK以上</h3>
                </article>
              </Link>
            </div>
          </div>

          <h2 className="indexTitle">共用設備から選ぶ</h2>
          <div className="indexPage columns is-12 has-text-centered is-multiline">
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("コンシェルジュ")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>コンシェルジュ</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("ゲストルーム")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>ゲストルーム</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("パーティールーム ")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>パーティールーム </h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("スカイラウンジ")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>スカイラウンジ</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("トランクルーム")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>トランクルーム</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("フィットネスジム")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>フィットネスジム</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("キッズルーム")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>キッズルーム</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("バーラウンジ")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>バーラウンジ</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("宅配ロッカー")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>宅配ロッカー</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("24時間有人管理")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>24時間有人管理</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("車寄せ")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>車寄せ</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("ペット可")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>ペット可</h3>
                </article>
              </Link>
            </div>
          </div>

          <h2 className="indexTitle">室内設備から選ぶ</h2>
          <div className="indexPage columns is-12 has-text-centered is-multiline">
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("ガスコンロ")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>ガスコンロ</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("IHコンロ")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>IHコンロ</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("床暖房")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>床暖房</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("シューズイン")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>シューズイン</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("食器洗浄機")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>食器洗浄機</h3>
                </article>
              </Link>
            </div>
            <div className="is-parent column topColumn is-4">
              <Link to={`/tags/${kebabCase("ディスポーザー")}/`}>
                <article className={`blog-list-item tile is-child box topBox`} >
                  <h3>ディスポーザー</h3>
                </article>
              </Link>
            </div>            
          </div>

          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="column is-12">
                    {/* <BlogRoll /> */}
                    <div className="column is-12 has-text-centered">
                      <Link className="btn" to="/blog">
                        タワマン一覧を見る
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 60) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        heading
        subheading
      }
    }
  }
`
